<template>
  <div class="swiper outside-swiper">
    <div class="">
      <div class="header">
        <div class="oxiIcon"></div>
        <div class="oxiTitle" ref="oxiTitle">OXI RENDER</div>
        <div class="changeIcon" @click="clickIcon" ref="changeIcon"></div>
      </div>
      <div class="maskFull" ref="maskHeight">
        <div class="mask_title_1" @click="clearMask" v-if="showValue">首页</div>
        <div class="mask_title_2" @click="linkHelp" v-if="showValue">
          帮助文档
        </div>
      </div>
    </div>
    <div class="swiper-wrapper">
      <div class="swiper-slide slide1">
        <div class="circleStyle">
          <p class="slide1_title">从创意想法到施工落地的<br />绝佳创作平台</p>
        </div>
        <div class="slide_1_button"></div>
      </div>
      <div class="swiper-slide slide2">
        <div class="swiper inside-swiper">
          <div class="slide2_title_1">简单工具</div>
          <div class="slide2_title_2">其实并不简单</div>
          <div class="slide_2_button_fix" ref="hideRightIcon">
            <div class="arrowBg"></div>
          </div>
          <div class="progress">
            <span class="block_1" ref="block_1"></span>
            <span class="block_2" ref="block_2"></span>
            <span class="block_3" ref="block_3"></span>
            <span class="block_4" ref="block_4"></span>
          </div>
          <div class="swiper-wrapper">
            <div class="swiper-slide slide2_1">
              <div class="slide_21_button" ref="mask_slide_21"></div>
              <div class="slide_pub_img slide_21_img"></div>
            </div>
            <div class="swiper-slide slide2_2">
              <div class="slide_22_button" ref="mask_slide_22"></div>
              <div class="slide_pub_img slide_22_img"></div>
            </div>
            <div class="swiper-slide slide2_3">
              <div class="slide_23_button" ref="mask_slide_23"></div>
              <div class="slide_pub_img slide_23_img"></div>
            </div>
            <div class="swiper-slide slide2_4">
              <div class="slide_24_button" ref="mask_slide_24"></div>
              <div class="slide_pub_img slide_24_img"></div>
            </div>
          </div>
        </div>
        <div class="slide_2_button"></div>
      </div>
      <div class="swiper-slide slide3">
        <div class="slide_3_img"></div>
        <div class="slide3_bg">
          <div class="slide3_title_1">所见即所得</div>
          <div class="slide3_title_2">设计真正可交付的空间</div>
          <div class="slide3_border"></div>
        </div>
        <div class="slide_3_button"></div>
      </div>
      <div class="swiper-slide slide4">
        <div class="slide_4_img"></div>
        <div class="slide4_title slide4_title_1">
          为了您更好的体验，请到PC端打开OXI RENDER
        </div>
        <div class="slide4_title slide4_title_2">官网并进行下载</div>
        <div class="slide4_title slide4_title_3" ref="demoInput">
          https://oxi.yangwudongxi.com
        </div>
        <div
          class="slide4_button"
          ref="slide4_button"
          v-clipboard:copy="value"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        ></div>
        <div class="footer">
          <div class="footer_title_1">
            Copyright @2022 杭州氧屋东西科技有限公司
          </div>

          <div class="footer_title_2">
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer"
              >备案号：浙ICP备2020043708号-1</a
            >
          </div>
          <div class="footer_other_area">
            <p>法律条款</p>
            <p class="footerLine">|</p>
            <p>联系我们</p>
            <p class="footerLine">|</p>
            <p>隐私政策</p>
            <p class="footerLine">|</p>
            <p>校园招聘</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import weiXinShare from '../util/shareWx.js'
import wx from 'weixin-js-sdk' //微信sdk依赖
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  name: 'MHome',
  data() {
    return {
      outSwiper: '',
      inSwiper: '',
      value: 'https://oxi.yangwudongxi.com',
      showValue: false,
      isWhite: true
    }
  },
  components: {
    Swiper
  },

  created() {},
  mounted() {
    this.swiperInit()
    this.$refs.mask_slide_21.addEventListener('pointerdown', this.clickdown_21)
    this.$refs.mask_slide_22.addEventListener('pointerdown', this.clickdown_22)
    this.$refs.mask_slide_23.addEventListener('pointerdown', this.clickdown_23)
    this.$refs.mask_slide_24.addEventListener('pointerdown', this.clickdown_24)
    this.$refs.mask_slide_21.addEventListener('pointerup', this.clickup)
    this.$refs.mask_slide_22.addEventListener('pointerup', this.clickup)
    this.$refs.mask_slide_23.addEventListener('pointerup', this.clickup)
    this.$refs.mask_slide_24.addEventListener('pointerup', this.clickup)

    this.getJSSDK()
  },
  methods: {
    swiperInit() {
      var scope = this
      this.outSwiper = new Swiper('.outside-swiper', {
        speed: 1000,
        direction: 'vertical',
        mousewheel: true,
        on: {
          init: function () {},
          slideChangeTransitionStart: function () {
            if (this.activeIndex == 0) {
              scope.$refs.changeIcon.style.background =
                "url('/img/moxi/more.png')"
              scope.$refs.changeIcon.style.backgroundSize = '100% 100%'
              scope.$refs.oxiTitle.style.color = '#FFFFFF'
              scope.isWhite = true
            } else if (this.activeIndex == 1) {
              scope.$refs.changeIcon.style.background =
                "url('/img/moxi/more_black.png')"
              scope.$refs.changeIcon.style.backgroundSize = '100% 100%'
              scope.$refs.oxiTitle.style.color = '#121212'
              scope.isWhite = false
            } else if (this.activeIndex == 2) {
              scope.$refs.changeIcon.style.background =
                "url('/img/moxi/more_black.png')"
              scope.$refs.changeIcon.style.backgroundSize = '100% 100%'
              scope.$refs.oxiTitle.style.color = '#121212'
              scope.isWhite = false
            } else if (this.activeIndex == 3) {
              scope.$refs.changeIcon.style.background =
                "url('/img/moxi/more.png')"
              scope.$refs.changeIcon.style.backgroundSize = '100% 100%'
              scope.$refs.oxiTitle.style.color = '#FFFFFF'
              scope.isWhite = true
            }
          }
        }
      })

      this.inSwiper = new Swiper('.inside-swiper', {
        speed: 1000,
        mousewheel: true,
        on: {
          init: function () {},
          slideChangeTransitionStart: function () {
            if (this.activeIndex == 0) {
              scope.blockPos_1()
            } else if (this.activeIndex == 1) {
              scope.blockPos_2()
            } else if (this.activeIndex == 2) {
              scope.blockPos_3()
            } else if (this.activeIndex == 3) {
              scope.blockPos_4()
            }
          }
        }
      })

      $('.slide_21_button').click(function () {
        scope.inSwiper.slideTo(1, 1000, false) //切换到第2个slide(下标为1)，速度为1秒
      })
      $('.slide_22_button').click(function () {
        scope.inSwiper.slideTo(2, 1000, false)
      })
      $('.slide_23_button').click(function () {
        scope.inSwiper.slideTo(3, 1000, false)
      })
      $('.slide_24_button').click(function () {
        scope.inSwiper.slideTo(0, 1000, false)
      })
      $('.slide_1_button').click(function () {
        scope.outSwiper.slideTo(1, 1000, false)
        scope.$refs.changeIcon.style.background =
          "url('/img/moxi/more_black.png')"
        scope.$refs.changeIcon.style.backgroundSize = '100% 100%'
        scope.$refs.oxiTitle.style.color = '#121212'
        scope.isWhite = false
      })
      $('.slide_2_button').click(function () {
        scope.outSwiper.slideTo(2, 1000, false)
        scope.$refs.changeIcon.style.background =
          "url('/img/moxi/more_black.png')"
        scope.$refs.changeIcon.style.backgroundSize = '100% 100%'
        scope.$refs.oxiTitle.style.color = '#121212'
        scope.isWhite = false
      })
      $('.slide_3_button').click(function () {
        scope.outSwiper.slideTo(3, 1000, false)
        scope.$refs.changeIcon.style.background = "url('/img/moxi/more.png')"
        scope.$refs.changeIcon.style.backgroundSize = '100% 100%'
        scope.$refs.oxiTitle.style.color = '#FFFFFF'
        scope.isWhite = true
      })
      // $('.mask_title_1').click(function () {
      //   scope.outSwiper.slideTo(0, 1000, false)
      // })
    },
    clickdown_21() {
      this.$refs.hideRightIcon.style.opacity = 0.3
      this.$refs.hideRightIcon.style.backgroundColor = '#007aff'
      this.blockPos_2()
    },
    clickdown_22() {
      this.$refs.hideRightIcon.style.opacity = 0.3
      this.$refs.hideRightIcon.style.backgroundColor = '#007aff'
      this.blockPos_3()
    },
    clickdown_23() {
      this.$refs.hideRightIcon.style.opacity = 0.3
      this.$refs.hideRightIcon.style.backgroundColor = '#007aff'
      this.blockPos_4()
    },
    clickdown_24() {
      this.$refs.hideRightIcon.style.opacity = 0.3
      this.$refs.hideRightIcon.style.backgroundColor = '#007aff'
      this.blockPos_1()
    },
    clickup() {
      this.$refs.hideRightIcon.style.opacity = 1
      this.$refs.hideRightIcon.style.backgroundColor = ''
    },

    // 复制成功时的回调函数
    onCopy(e) {
      this.$message.success('OXI RENDER网址已复制到剪切板！')
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message.error('抱歉，复制失败！')
    },
    clickIcon() {
      this.showValue = !this.showValue
      if (!this.showValue) {
        this.$refs.changeIcon.style.transform = 'rotate(180deg)'
        let iconTime = setTimeout(() => {
          if (this.isWhite) {
            this.$refs.changeIcon.style.background = "url('/img/moxi/more.png')"
          } else {
            this.$refs.changeIcon.style.background =
              "url('/img/moxi/more_black.png')"
          }
          this.$refs.changeIcon.style.backgroundSize = '100% 100%'
          this.$refs.changeIcon.style.transform = 'rotate(0deg)'
          clearTimeout(iconTime)
        }, 1000)
        this.$refs.maskHeight.style.height = 0
      } else {
        this.$refs.changeIcon.style.background = "url('/img/moxi/x.png')"
        this.$refs.changeIcon.style.backgroundSize = '100% 100%'
        this.$refs.maskHeight.style.height = '100%'
      }
    },
    clearMask() {
      this.clickIcon()
    },
    linkHelp() {
      window.open('http://design-help.bj.yangwudongxi.com')
    },
    blockPos_1() {
      this.$refs.block_1.style.width = '30px'
      this.$refs.block_2.style.width = '10px'
      this.$refs.block_3.style.width = '10px'
      this.$refs.block_4.style.width = '10px'

      this.$refs.block_1.style.backgroundColor = '#b88f58'
      this.$refs.block_2.style.backgroundColor = '#cccccc'
      this.$refs.block_3.style.backgroundColor = '#cccccc'
      this.$refs.block_4.style.backgroundColor = '#cccccc'
    },
    blockPos_2() {
      this.$refs.block_1.style.width = '10px'
      this.$refs.block_2.style.width = '30px'
      this.$refs.block_3.style.width = '10px'
      this.$refs.block_4.style.width = '10px'

      this.$refs.block_1.style.backgroundColor = '#cccccc'
      this.$refs.block_2.style.backgroundColor = '#b88f58'
      this.$refs.block_3.style.backgroundColor = '#cccccc'
      this.$refs.block_4.style.backgroundColor = '#cccccc'
    },
    blockPos_3() {
      this.$refs.block_1.style.width = '10px'
      this.$refs.block_2.style.width = '10px'
      this.$refs.block_3.style.width = '30px'
      this.$refs.block_4.style.width = '10px'

      this.$refs.block_1.style.backgroundColor = '#cccccc'
      this.$refs.block_2.style.backgroundColor = '#cccccc'
      this.$refs.block_3.style.backgroundColor = '#b88f58'
      this.$refs.block_4.style.backgroundColor = '#cccccc'
    },
    blockPos_4() {
      this.$refs.block_1.style.width = '10px'
      this.$refs.block_2.style.width = '10px'
      this.$refs.block_3.style.width = '10px'
      this.$refs.block_4.style.width = '30px'

      this.$refs.block_1.style.backgroundColor = '#cccccc'
      this.$refs.block_2.style.backgroundColor = '#cccccc'
      this.$refs.block_3.style.backgroundColor = '#cccccc'
      this.$refs.block_4.style.backgroundColor = '#b88f58'
    },

    getJSSDK() {
      axios
        .post(
          'https://testminsoft.zhaidoudou.com/wx/v1/signature/web/encrypt',
          {
            url: location.href.split('#')[0]
          }
        )
        .then((res) => {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: 'wxc36af7b34e240f36', // 必填，公众号的唯一标识
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
          })

          wx.ready(function () {
            wx.updateAppMessageShareData({
              title: 'OXI RENDER', // 分享标题
              desc: '所见即所得，从创意想法到施工落地的绝佳创作平台，设计真正可交付的空间', // 分享描述
              link: location.href.split('#')[0],
              imgUrl:
                'https://3d.h5.zhaidoudou.com/pro/wxShare/oxiRender/kitchen.png', // 分享图标
              success: function () {
                // 设置成功
              }
            })

            wx.updateTimelineShareData({
              title:
                'OXI RENDER 从创意想法到施工落地的绝佳创作平台，设计真正可交付的空间', // 分享标题
              link: location.href.split('#')[0],
              imgUrl:
                'https://3d.h5.zhaidoudou.com/pro/wxShare/oxiRender/kitchen.png', // 分享图标
              success: function () {
                // 设置成功
              }
            })
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.swiper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
}
.outside-swiper {
  top: 0;
  left: 0;
}

.swiper-slide {
  width: 100%;
  height: 100%;
}
.slide1 {
  background: url('/img/moxi/pic_1.png') no-repeat;
  background-size: 100% 100%;
}

.slide_pub_img {
  position: absolute;
  top: 217.5px;
  left: 50%;
  transform: translateX(-50%);
}
.slide_21_img {
  width: 335px;
  height: 398px;
  background: url('/img/moxi/pic_2_1.png') no-repeat;
  background-size: 100% 100%;
}
.slide_22_img {
  width: 335px;
  height: 398px;
  background: url('/img/moxi/pic_2_2.png') no-repeat;
  background-size: 100% 100%;
}
.slide_23_img {
  width: 335px;
  height: 398px;
  background: url('/img/moxi/pic_2_3.png') no-repeat;
  background-size: contain;
}
.slide_24_img {
  width: 335px;
  height: 398px;
  background: url('/img/moxi/pic_2_4.png') no-repeat;
  background-size: contain;
}

.slide3_bg {
  position: absolute;
  top: 449.5px;
  left: 0;
  width: 375px;
  height: 298.5px;
  background-color: #b88f58;
}
.slide_3_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 375px;
  height: 449.5px;
  background: url('/img/moxi/pic_3.png') no-repeat;
  background-size: 100% auto;
}
.slide3_title_1 {
  position: absolute;
  top: 85px;
  left: 20px;
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  line-height: 20px;
}
.slide3_title_2 {
  position: absolute;
  top: 121px;
  left: 20px;
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  line-height: 20px;
}
.slide3_border {
  position: absolute;
  top: 151px;
  left: 20px;
  width: 211px;
  height: 1px;
  background-color: #ffffff;
}

.slide_4_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 375px;
  height: 595px;
  background: url('/img/moxi/pic_4.png') no-repeat;
  background-size: 100% 100%;
}

.slide_1_button,
.slide_2_button {
  position: absolute;
  top: 653px;
  left: 50%;
  transform: translateX(-50%);
  width: 21px;
  height: 20px;
  background: url('/img/moxi/arrow.png') no-repeat;
  background-size: contain;
  animation: flashButton 1s infinite;
}

.slide_3_button {
  position: absolute;
  top: 653px;
  left: 50%;
  transform: translateX(-50%);
  width: 21px;
  height: 20px;
  background: url('/img/moxi/arrow_white.png') no-repeat;
  background-size: contain;
  animation: flashButton 1s infinite;
}
@keyframes flashButton {
  0% {
    opacity: 0.3;
    top: 653px;
  }

  50% {
    opacity: 1;
    top: 648px;
  }

  100% {
    opacity: 0.3;
    top: 653px;
  }
}
.slide_2_button_fix {
  position: absolute;
  top: 154.25px;
  left: 20px;
  width: 46.5px;
  height: 46.5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.arrowBg {
  width: 46.5px;
  height: 20.5px;
  background: url('/img/moxi/right.png') no-repeat;
  background-size: 100% auto;
}
.slide_21_button,
.slide_22_button,
.slide_23_button,
.slide_24_button {
  position: absolute;
  top: 153px;
  left: 20px;
  width: 50px;
  height: 50px;
  /* background-color: rgba(110, 33, 33, 0.705); */
}
.circleStyle {
  position: absolute;
  top: 160.5px;
  left: 50%;
  transform: translateX(-50%);
  width: 317px;
  height: 317px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.22);

  font-size: 21px;
  font-weight: 300;
  color: #ffffff;
  line-height: 47px;
}
.slide1_title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.slide2_1 {
  font-size: 20px;
  font-weight: 300;
  color: #121212;
  line-height: 40px;
}
.slide2_title_1 {
  position: absolute;
  top: 79.5px;
  left: 20px;
  font-size: 20px;
  font-weight: 300;
  color: #121212;
  line-height: 20px;
}
.slide2_title_2 {
  position: absolute;
  top: 115.5px;
  left: 20px;
  font-size: 20px;
  font-weight: 300;
  color: #121212;
  line-height: 20px;
}
.progress {
  position: absolute;
  left: 259px;
  top: 631.5px;
  height: 10px;
  width: 96px;
  /* background-color: rgba(255, 255, 255, 1); */
  z-index: 2;
  display: flex;
  justify-content: space-between;
}
.block_1 {
  width: 30px;
  height: 10px;
  background-color: #b88f58;
  /* background-color: #1f6cc0; */
  z-index: 3;
  transition: width 1s;
}
.block_2 {
  width: 10px;
  height: 10px;
  background-color: #cccccc;
  /* background-color: #dd578d; */
  z-index: 3;
  transition: width 1s;
}
.block_3 {
  width: 10px;
  height: 10px;
  background-color: #cccccc;
  /* background-color: #eaa156; */
  z-index: 3;
  transition: width 1s;
}
.block_4 {
  width: 10px;
  height: 10px;
  /* background-color: #8d94c1; */
  background-color: #cccccc;
  z-index: 3;
  transition: width 1s;
}
.slide4_title {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 28.5px;
  z-index: 2;
}
.slide4_title_1 {
  top: 251px;
}
.slide4_title_2 {
  top: 280px;
}
.slide4_title_3 {
  top: 334.5px;
}
.slide4_button {
  position: absolute;
  top: 387.5px;
  left: 50%;
  transform: translateX(-50%);
  width: 114px;
  height: 44px;
  background: url('/img/moxi/square.png') no-repeat;
  background-size: 100% 100%;
  border-radius: 5px;
  overflow: hidden;
}
/* top: 635px; */
.footer {
  position: absolute;
  top: 595px;
  left: 0;
  width: 375px;
  height: 113px;
  font-size: 10px;
  font-weight: 300;
  color: #757575;
  line-height: 10px;
  text-align: center;
}
.footer_title_1 {
  position: absolute;
  top: 16px;
  width: 100%;
}
.footer_title_2 {
  position: absolute;
  top: 42px;
  width: 100%;
}
.footer_other_area {
  position: absolute;
  top: 68px;
  left: 50%;
  transform: translateX(-50%);
  width: 234px;
  height: 10px;
  display: flex;
  justify-content: space-between;
}
.footerLine {
  color: #d8d8d8;
}
.outShow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgb(0, 0, 0); */
  z-index: 5;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 71px;
  /* background-color: rgba(26, 187, 179, 0.63); */
  z-index: 5;
}
.oxiIcon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 31px;
  height: 31px;
  background: url('/img/start.png') no-repeat;
  background-size: 100% 100%;
}
.oxiTitle {
  position: absolute;
  top: 0;
  left: 54px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 71px;
}
.changeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: url('/img/moxi/more.png') no-repeat;
  background-size: 100% 100%;
  transform: rotate(0deg);
  transition: transform 0.5s;
}
.maskFull {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #141414;
  z-index: 4;
  transition: height 0.5s;
}

.mask_title_1 {
  position: absolute;
  top: 285px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
  animation: showTitle 1.5s ease-out;
}
.mask_title_2 {
  position: absolute;
  top: 337.5px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #6a6a6a;
  line-height: 36px;
  animation: showTitle 2s ease-out;
}
@keyframes showTitle {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
a {
  text-decoration: none;
  color: #757575;
}
a:hover {
  color: #757575;
}
</style>
